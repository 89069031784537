import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IUser, IUserPreference } from 'app/core/user/user.types';
import { map, Observable, of, ReplaySubject, shareReplay, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppModulesEnum, WorkloadServicesEnum } from '../../types/app.interface';

@Injectable({ providedIn: 'root' })
export class UserService {
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<IUser> = new ReplaySubject<IUser>(1);
    private _userRequest$: Observable<IUser> | null = null; // To cache the HTTP request

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: IUser) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<IUser> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<IUser> {
        // Avoid making multiple HTTP calls by caching the request
        if (!this._userRequest$) {
            this._userRequest$ = this._httpClient.get<IUser>(`${environment.apiUrl}/network/w-users/self`).pipe(
                tap((user) => this._user.next(user)), // Emit the fetched user
                shareReplay(1) // Cache the HTTP request result
            );
        }

        // Return the cached request observable or the ReplaySubject
        return this._userRequest$;
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: IUser): Observable<any> {
        return this._httpClient.patch<IUser>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    getUserPreferences(module: AppModulesEnum, service: WorkloadServicesEnum): Observable<any> {
        return this._httpClient.get(`${environment.apiUrl}/network/user-preferences?filters[module][eq]=${module}&filters[service][eq]=${service}`);
    }

    createUserPreference(preference: IUserPreference): Observable<IUserPreference> {
        return this._httpClient.post<IUserPreference>(`${environment.apiUrl}/network/user-preferences`, preference);
    }

    updateUserPreference(id: string, preference: IUserPreference): Observable<IUserPreference> {
        return this._httpClient.put<IUserPreference>(`${environment.apiUrl}/network/user-preferences/${id}`, preference);
    }

    deletePreference(id: string): Observable<any> {
        return this._httpClient.delete(`${environment.apiUrl}/network/user-preferences/${id}`);
    }
}
