<!-- Button -->

    <button mat-icon-button [matMenuTriggerFor]="userActions" class="relative">
        @if (isLoading) {
            <div>
                <mat-spinner [diameter]="20"></mat-spinner>
            </div>
        } @else {
        <span class=" flex h-7 w-7 min-h-7 min-w-7 rounded-full border border-primary-700 overflow-hidden items-center justify-center">
            @if (showAvatar && user?.thumbUrl) {
                <img alt="" [src]="user.thumbUrl" class="object-cover w-full h-full"/>
            }
            @if (!showAvatar || !user?.thumbUrl) {
                <span
                    class="text-xs text-primary-600 font-medium"> {{ getInitials(user?.name) }}</span>
            }
            <span
                class="absolute bottom-1 right-1 h-2 w-2 rounded-full"
                [ngClass]="{
                    'mb-px mr-px': !showAvatar || !user?.thumbUrl,
                    'bg-green-500': user?.status === 'online',
                    'bg-amber-500': user?.status === 'away',
                    'bg-red-500': user?.status === 'busy',
                    'bg-gray-400': user?.status === 'not-visible',
                }"
            ></span>
        </span>
        }
    </button>

    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>Signed in as</span>
                <span class="mt-1.5 text-md font-medium">{{ user?.email }}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>Profile</span>
        </button>
        <button mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
            <span>Settings</span>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="userStatus">
            <mat-icon
                [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"
            ></mat-icon>
            <span>Status</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
            <mat-icon
                [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
            ></mat-icon>
            <span>Sign out</span>
        </button>
    </mat-menu>

    <mat-menu class="user-status-menu" #userStatus="matMenu">
        <button mat-menu-item (click)="updateUserStatus('online')">
            <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-green-500"></span>
            <span>Online</span>
        </button>
        <button mat-menu-item (click)="updateUserStatus('away')">
            <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-amber-500"></span>
            <span>Away</span>
        </button>
        <button mat-menu-item (click)="updateUserStatus('busy')">
            <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-red-500"></span>
            <span>Busy</span>
        </button>
        <button mat-menu-item (click)="updateUserStatus('not-visible')">
            <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-gray-400"></span>
            <span>Invisible</span>
        </button>
    </mat-menu>

