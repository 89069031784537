import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IconsService } from './core/icons/icons.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private iconsService: IconsService,
    ) {
       this.iconsService.initIcons();
    }
}
